import React, { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Button } from 'components/button-new';
import { Container } from 'components/container';
import { CONSTANTS } from 'constants/styles/constants';
import { GatsbyImage } from 'gatsby-plugin-image';
import { string } from 'prop-types';

import { useAISectionData } from './hooks';

const SWrapper = styled.div`
    background-color: var(--secondary-color);
`;

const SContainer = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SContainerBackground = styled.div`
    padding: 5rem;
    background-color: var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SContent = styled.div`
    color: var(--white-color);
    ${CONSTANTS.MEDIA.max1024`
        text-align: center;
    `}
`;

const SImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    ${CONSTANTS.MEDIA.max1024`
        display: none;
    `}
`;

const SHeader = styled.p`
    display: inline-block;
    max-width: 58rem;
    padding-bottom: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: clamp(1.75rem, 1.5739rem + 0.7512vw, 2.25rem);
    width: 400px;
    ${CONSTANTS.MEDIA.max1024`
        padding-left: 2rem;
        padding-right: 2rem;
    `}
`;

const SText = styled.p`
    font-size: 1.25rem;
    line-height: 1.5;
    padding-bottom: 2.5rem;
    font-weight: 300;
    ${CONSTANTS.MEDIA.max1024`  
        padding-left: 2rem;
        padding-right: 2rem;
    `}
`;

const SSubtext = styled(SText)`
    padding-bottom: 3rem;
    font-weight: 500;
`;

const SGatsbyImage = styled(GatsbyImage)`
    width: 720px;
    height: 531px;
    ${CONSTANTS.MEDIA.min1024max1200`
        width: 500px;
        height: 369px;
    `}
`;

const SLinkButton = styled(Button)`
    width: ${({ alt }) => (alt ? '7.5rem' : '15.75rem')};
    background-color: var(--white-color);
    border: unset;

    &:hover {
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.1),
                rgba(0, 0, 0, 0.1)
            ),
            var(--white-color);
        span {
            color: var(--black-color);
        }
    }

    span {
        font-size: 0.875rem;
        color: var(--black-color);
    }
`;

export const AIAssistantSection = ({ className }) => {
    const ref = useRef(null);
    const { formatMessage } = useIntl();
    const { image } = useAISectionData();

    console.log(process.env.GATSBY_AI_ASSISTANT_URL);

    return (
        <SWrapper ref={ref}>
            <SContainer className={className} id="ai-assistant">
                <SContainerBackground>
                    <SImageWrapper>
                        <SGatsbyImage image={image} alt="alt text" />
                    </SImageWrapper>
                    <SContent>
                        <SHeader>
                            {formatMessage({
                                id: 'ai-assistant-section.header',
                            })}
                        </SHeader>
                        <SText>
                            {formatMessage({ id: 'ai-assistant-section.text' })}
                        </SText>
                        <SSubtext>
                            {formatMessage({
                                id: 'ai-assistant-section.subtext',
                            })}
                        </SSubtext>
                        <SLinkButton
                            to={process.env.GATSBY_AI_ASSISTANT_URL}
                            target="_blank"
                            component="a"
                        >
                            <FormattedMessage id="ai-assistant-section.button" />
                        </SLinkButton>
                    </SContent>
                </SContainerBackground>
            </SContainer>
        </SWrapper>
    );
};

AIAssistantSection.propTypes = {
    className: string,
};

AIAssistantSection.defaultProps = {
    className: '',
};
