import React from 'react';
import { Index } from 'views/index';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import blogMessages from 'constants/translations/blog.json';
import caseStudiesMessages from 'constants/translations/case-studies/index.json';
import messages from 'constants/translations/index.json';
import aiAssistantMessages from 'constants/translations/ai-assistant.json';
import { graphql } from 'gatsby';
import Layout from 'layouts/standard';
import { object } from 'prop-types';
import { formatFaqData } from 'utilities/contentful';

const IndexPage = ({ location, data }) => {
    const blogPosts = data?.allContentfulBlogPost?.edges || [];
    const faqData = formatFaqData(data?.allContentfulFaq.nodes[0]);

    return (
        <Layout
            location={location}
            messages={{
                ...caseStudiesMessages,
                ...blogMessages,
                ...messages,
                ...aiAssistantMessages,
            }}
        >
            <Index {...{ blogPosts, faqData }} />
        </Layout>
    );
};

IndexPage.propTypes = {
    location: object.isRequired,
};

export default IndexPage;

export const Head = () => (
    <SEO tags={TAGS.HOME}>
        <>
            <meta
                name="facebook-domain-verification"
                content="c7mzkaivhepinclrk6yk79wkzvfwfn"
            />
            <meta
                name="facebook-domain-verification"
                content="9eh54bc7bzpl7z016f9j2g4rvels33"
            />
            <meta
                name="facebook-domain-verification"
                content="wnhlpc8bh2vqgnuwmo51z7cili5kvi"
            />
        </>
    </SEO>
);

export const pageQuery = graphql`
    query EnIndexPageQuery {
        allContentfulBlogPost(
            filter: {
                node_locale: { eq: "en-US" }
                metadata: {
                    tags: {
                        elemMatch: {
                            name: { nin: ["press-release", "nft-reality"] }
                        }
                    }
                }
            }
            sort: { date: DESC }
            limit: 3
        ) {
            edges {
                node {
                    ...BlogPostFragment
                }
            }
        }
        allContentfulFaq(filter: { slug: { eq: "/" } }) {
            nodes {
                ...FaqFragment
            }
        }
    }
`;
