import { graphql, useStaticQuery } from 'gatsby';

export const useAISectionData = () => {
    const images = useStaticQuery(graphql`
        {
            ai: file(relativePath: { eq: "ai-assistant/ai-face.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 50
                        width: 720
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
        }
    `);

    return {
        image: images.ai.childImageSharp.gatsbyImageData,
        altKey: 'ai-assistant-section.alts.image',
    };
};
